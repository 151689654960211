// import { paint } from '../components/LawDetails'

const chartOptions = {
	scales: {
		yAxes: {
			stacked: true,
			grid: {
				display: true,
				color: "#B7B8C1",
			},
		},

		xAxes: {
			grid: {
				display: false,
			},
		},
	},
	elements: {
		point: {
			backgroundColor: "#4169E1",
			pointStyle: "line",
			borderWidth: "",
		},
		line: { backgroundColor: "#4169E1" },
	},
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: true,
			text: "Sales Summary",
			position: "top",
		},
	},
	tension: 0.4,
	borderColor: "#4169E1",
	borderWidth: "2",
	borderJointStyle: "",
};
export default chartOptions;
