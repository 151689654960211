import React from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { deleteSession } from "../utils/session";

const TopBar = () => {
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const logout = () => {
		deleteSession();
		navigate("/");
	};

	return (
		<div className="top">
			{/* <img
        src={`${process.env.PUBLIC_URL}/images/${params.id}.png`}
        alt="State Logo"
      /> */}

			<Link to={location.pathname.split("/laws")[0]}>
				<img
					src="/images/LOGO.png"
					alt="Navigate back"
				/>
			</Link>
			<div
				className="logout"
				onClick={logout}>
				<img
					src="/images/back.png"
					alt=""
				/>
				<span>Log Out</span>
			</div>
		</div>
	);
};

export default TopBar;
