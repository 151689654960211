import React from "react";

const SalesCard = ({ label, value, icon, color, child }) => {
	return (
		<div className="summary__card">
			<div className={`card-icon card-icon--${color}`}>
				<img
					src={`/images/${icon}.png`}
					alt=""
				/>
			</div>
			<div className="card-text">
				<p className="card-label">
					{label} {child}
				</p>
				<p className="card-value">
					{value.toLocaleString("en-NG", {
						style: "currency",
						currency: "NGN",
					})}
				</p>
			</div>
		</div>
	);
};

export default SalesCard;
