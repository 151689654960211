import React from "react";

const FormHeadings = ({ heading, subheading }) => {
	return (
		<>
			<h2 className="auth__card--heading">{heading}</h2>
			<h4 className="auth__card--subheading">{subheading}</h4>
		</>
	);
};

export default FormHeadings;
