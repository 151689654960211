import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { deleteAdminSession } from "../../utils/session";

const Menu = () => {
	const location = useLocation();
	const parentURL = location.pathname.split("/")[1];
	const navigate = useNavigate();
	const logout = () => {
		deleteAdminSession();
		navigate("/admin/login");
	};

	useEffect(() => {
		return () => {};
	}, []);

	return (
		<div className="menu">
			<div className="menu__items">
				<NavLink
					to="/admin/"
					className={({ isActive, isPending }) => {
						return isActive
							? "menu__item menu__item--active"
							: isPending
							? "menu__item menu__item--pending"
							: "menu__item";
					}}>
					<span className="menu__icon menu__icon--overview"></span>
					<span>Overview</span>
				</NavLink>
				<NavLink
					to="#"
					className="menu__item">
					<span className="menu__icon menu__icon--users"></span>
					<span>Users</span>
				</NavLink>
				<NavLink
					to="#"
					className="menu__item">
					<span className="menu__icon menu__icon--states"></span>
					<span>States</span>
				</NavLink>
				<NavLink
					to="transactions"
					className={({ isActive, isPending }) => {
						return isActive
							? "menu__item menu__item--active"
							: isPending
							? "menu__item menu__item--pending"
							: "menu__item";
					}}>
					<span className="menu__icon menu__icon--transactions"></span>
					<span>Transactions</span>
				</NavLink>
			</div>
			<div
				className="logout logout__admin"
				onClick={logout}>
				<img
					src="/images/back.png"
					alt=""
				/>
				<span className="logout__text">Log Out</span>
			</div>
		</div>
	);
};

export default Menu;
