import React, { useState } from "react";
import { useField } from "formik";

const Input = ({ label, className, ...props }) => {
	const [field, meta] = useField(props);
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [passwordFieldType, setPasswordFieldType] = useState("password");

	const togglePasswordVisibility = (e) => {
		setPasswordVisibility(!passwordVisibility);

		switch (passwordFieldType) {
			case "password":
				setPasswordFieldType("text");
				break;

			default:
				setPasswordFieldType("password");
				break;
		}
	};

	return (
		<>
			<div className="auth__form-group">
				<label
					htmlFor={props.id || props.name}
					className="auth__form-label">
					{label}{" "}
				</label>
				<input
					className={`${className} ${
						meta.touched && meta.error ? className + "--error " : ""
					}`}
					type={passwordFieldType}
					{...field}
					{...props}
				/>

				{props.type === "password" ? (
					<>
						<img
							src="/images/eye.png
                                    "
							alt="show password"
							className={`auth__form-icon ${
								!passwordVisibility
									? "auth__form-icon--show"
									: ""
							}`}
							onClick={togglePasswordVisibility}
						/>
						<img
							src="/images/hide.png
                                    "
							alt="show password"
							className={`auth__form-icon ${
								passwordVisibility
									? "auth__form-icon--hide"
									: ""
							}`}
							onClick={togglePasswordVisibility}
						/>
					</>
				) : null}
			</div>
			{meta.touched && meta.error ? (
				<span className="auth__error">{meta.error}</span>
			) : null}
		</>
	);
};

export default Input;
