import React from "react";
import { Outlet } from "react-router-dom";
import Menu from "../components/Admin/Menu";

const AdminDashboard = () => {
	return (
		<div className="dashboard">
			<div className="dashboard__topbar">
				<div className="dashboard__logo">
					<img
						src="/images/gavel.svg"
						alt="gavel"
					/>
					<span>State Laws</span>
				</div>
				<div className="dashboard__title">Admin Dashboard</div>
			</div>
			<div className="dashboard__view">
				<Menu />
				<div className="dashboard__content">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default AdminDashboard;
