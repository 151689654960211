import React from "react";

const OverviewCard = ({ image, label, text, color }) => {
	return (
		<div className="overview-card">
			<div className="overview-card__top">
				<div
					className={`overview-card__icon ${
						color ? "overview-card__icon--" + color : ""
					}`}>
					<img
						src={image}
						alt="icon"
					/>
				</div>

				<p className="overview-card__label">{label}</p>
			</div>
			<span className="overview-card__value">{text}</span>
		</div>
	);
};

export default OverviewCard;
