import { useField } from "formik";
import React from "react";

const CheckBox = ({ ...props }) => {
	const [field, meta] = useField({ ...props, type: "checkbox" });

	return (
		<div className="auth__form-check">
			<input
				type="checkbox"
				{...field}
				{...props}
			/>
			<label htmlFor={props.id || props.name}>{props.text}</label>
		</div>
	);
};

export default CheckBox;
