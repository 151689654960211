import { currentEnvironment } from "./environment";

const adminAPI = () =>
	process.env.NODE_ENV == "production"
		? "https://store.lawpavilion.com/api/lawrecom-admin"
		: "https://store-staging.lawpavilion.com/api/lawrecom-admin";

const userAuthAPI = () =>
	process.env.NODE_ENV == "production"
		? "https://store.lawpavilion.com/api/lawrecom-user"
		: "https://store-staging.lawpavilion.com/api/lawrecom-user";

const statAPI = () =>
	process.env.NODE_ENV == "production"
		? "https://store.lawpavilion.com/api/state-law"
		: "https://store-staging.lawpavilion.com/api/state-law";

const baseAPI = () =>
	process.env.NODE_ENV == "production"
		? "https://store.lawpavilion.com/api"
		: "https://store-staging.lawpavilion.com/api";

export { adminAPI, userAuthAPI, statAPI, baseAPI };
