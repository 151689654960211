import React from 'react'

const Paginator = ({
  perPage,
  totalLaws,
  changePage,
  currentPage,
  pageGroup,
}) => {
  const pageLimit = Math.ceil(totalLaws / perPage)

  return (
    <div className="paginator">
      <span
        className={`paginator__control ${
          currentPage === 1 && 'paginator__hide'
        }`}
        onClick={() => changePage(currentPage - 1)}
      >
        &#xab;
      </span>
      {pageGroup.map((page) => (
        <span
          key={page}
          className={`paginator__control ${
            page > pageLimit && 'paginator__hide '
          } ${currentPage === page && 'paginator__control--active'}`}
          onClick={() => changePage(page)}
        >
          {page}
        </span>
      ))}

      <span
        className={`paginator__control ${
          currentPage === pageLimit && 'paginator__hide'
        }`}
        onClick={() => changePage(currentPage + 1)}
      >
        &#xbb;
      </span>
    </div>
  )
}

export default Paginator
