import React, { useEffect, useState } from "react";
import { Formik, Form, rese } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { adminAPI, baseAPI } from "../../utils/apiStrings";
import Input from "../FormComponents/Input";
import Select from "../FormComponents/Select";
import Button from "../FormComponents/Button";

const NewUserForm = ({ visibility }) => {
	const [apiError, setApiError] = useState("");
	const [statesData, setStatesData] = useState([]);
	const [countries, setCountries] = useState([]);
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState("");

	const getStates = async () => {
		let stateArray = [];
		try {
			const response = await axios.get(`${baseAPI()}/states`);
			const {
				data: { data: states },
			} = response;

			for (const state of states) {
				stateArray.push({ id: state.id, name: state.name });
			}
			setStatesData(stateArray);
		} catch (error) {
			setApiError(error.response.data.message);
		}
	};

	const getCountries = async () => {
		try {
			const {
				data: { data: options },
			} = await axios.get(`${baseAPI()}/registration-options`);
			const { countries } = options;

			setCountries(countries);
		} catch (error) {
			setApiError(error.response.data.message);
		}
	};

	const userDetails = {
		username: "",
		email: "",
		state_id: "",
		phone_number: "",
	};

	const validation = {
		username: Yup.string().required("Provide last name for user"),
		email: Yup.string()
			.email("Invalid email address")
			.required("Provide email address"),
		state_id: Yup.number().required("Provide a state").min(1),
		phone_number: Yup.string().matches(new RegExp("[0-9]{8}")),
	};

	const addUser = async (values, { resetForm }) => {
		setLoading(true);
		try {
			const response = await axios.post(`${adminAPI()}/register`, values);
			setResponse(response.data.message);
			setLoading(false);
			resetForm();
		} catch (error) {
			setLoading(false);
			setApiError(error.response.data.message);
		}
	};

	useEffect(() => {
		getStates();
		getCountries();
		return () => {};
	}, [loading]);

	return (
		<div className={`new-user ${visibility ? "new-user__visible" : ""}`}>
			<Formik
				initialValues={userDetails}
				validationSchema={Yup.object(validation)}
				onSubmit={addUser}>
				<Form className="new-user__form">
					<Input
						type="text"
						className="auth__form-control"
						name="username"
						label="User Name"
						id="username"
					/>

					<Input
						type="email"
						className="auth__form-control"
						name="email"
						label="Email Address"
						id="email"
					/>
					<Input
						type="text"
						className="auth__form-control"
						name="phone_number"
						label="Phone Number"
						id="phone_number"
					/>
					<Select
						className="auth_form-control"
						name="state_id"
						label="State"
						id="state_id"
						options={statesData}
						placeholder="Select State"
					/>
					{response || apiError ? (
						<span
							className={`auth__${
								apiError ? "error" : response ? "success" : ""
							}`}>
							{apiError || response}
						</span>
					) : null}
					<Button
						type="submit"
						text="Create User"
						loading={loading}
					/>
				</Form>
			</Formik>
		</div>
	);
};

export default NewUserForm;
