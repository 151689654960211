import axios from "axios";
import { currentEnvironment } from "../utils/environment";
import { Period } from "../utils/period";

const devEnvironment = currentEnvironment();

const APP_KEY = devEnvironment
	? process.env.REACT_APP_CRM_APP_KEY
	: process.env.REACT_APP_CRM_APP_KEY_LIVE;
const AUTH = devEnvironment
	? process.env.REACT_APP_CRM_AUTHORIZATION
	: process.env.REACT_APP_CRM_AUTHORIZATION_LIVE;

const baseURL = `https://store${
	devEnvironment ? "-staging" : ""
}.lawpavilion.com/api`;

export default class State {
	static findStateLaws = async (stateId) => {
		const { data } = await axios.get(
			`${baseURL}/states/${stateId}?with_laws=1`
		);
		return data;
	};

	static fetchStateLaws = async (stateId) => {
		try {
			const response = await axios.get(
				`${baseURL}/states/${stateId}?with_laws=1`
			);

			const { laws, name } = response.data.data;

			return { laws, name };
		} catch (error) {}
	};

	static getAnnualSub = async (period) => {
		try {
			const response = await axios.get(
				`https://crm${
					devEnvironment ? "-staging" : ""
				}.lawpavilion.com/api/v2/transactions/lagos-state-laws?APP_KEY=${APP_KEY}&period=${period}`,
				{
					headers: {
						"Content-type": "application/.json",
						Authorization: AUTH,
					},
				}
			);

			return response.data.data;
		} catch (error) {
			return error;
		}
	};

	static filterAnnualSub = async (startDate, endDate) => {
		const start = Period.setDateFormat(startDate);
		const end = Period.setDateFormat(endDate);

		try {
			const response = await axios.get(
				`https://crm${
					devEnvironment ? "-staging" : ""
				}.lawpavilion.com/api/v2/transactions/lagos-state-laws?APP_KEY=${APP_KEY}&start_date=${start}&end_date=${end}`,
				{
					headers: {
						"Content-type": "application/.json",
						Authorization: AUTH,
					},
				}
			);

			return response.data.data;
		} catch (error) {
			return error;
		}
	};

	static getIndividualSales = async (token, period) => {
		try {
			const response = await axios.get(
				`${baseURL}/state-law/all-sales?period=${period}`,
				{ headers: { Authorization: "Bearer " + token } }
			);

			const {
				total_outright_sales: outright,
				total_non_outright_sales: nonOutright,
			} = response.data.data;

			return { outright, nonOutright };
		} catch (error) {
			return error;
		}
	};

	static filterIndividualSales = async (token, startDate, endDate) => {
		const start = Period.setDateFormat(startDate);
		const end = Period.setDateFormat(endDate);

		try {
			const response = await axios.get(
				`${baseURL}/state-law/all-sales/filter?from_date=${start}&to_date=${end}`,
				{ headers: { Authorization: "Bearer " + token } }
			);

			const {
				total_outright_sales: outright,
				total_non_outright_sales: nonOutright,
			} = response.data.data;

			return { outright, nonOutright };
		} catch (error) {
			return error;
		}
	};
}
