export class Period {
	static getCurrentQuarter = (date = new Date()) => {
		const quarter = Math.floor(date.getMonth() / 3 + 1);

		switch (quarter) {
			case 1:
				return "first_quarter";

			case 2:
				return "second_quarter";

			case 3:
				return "third_quarter";
			case 4:
				return "fourth_quarter";
			default:
				return "";
		}
	};

	static setDateFormat = (dateString) => {
		let formattedDate = "";

		if (dateString) {
			const dateObject = new Date(dateString);
			formattedDate = dateObject.toISOString().split("T")[0];
		}

		return formattedDate;
	};

	static formatIntervalRequest(startDate, endDate) {
		let start = "",
			end = "";

		if (startDate && endDate) {
			start = this.setDateFormat(startDate);
			end = this.setDateFormat(endDate);
		}

		return { start, end };
	}

	static getPeriodRange = (quarterValue) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		const quarter =
			quarterValue ?? Math.floor(new Date().getMonth() / 3 + 1);

		const startMonth = (quarter - 1) * 3;
		const endMonth = startMonth + 2;

		return `Quarterly Overview (${monthNames[startMonth]} - ${
			monthNames[endMonth]
		} ${new Date().getFullYear()})`;
	};

	static formatDateRange = (start, end) => {
		const formattedStartDate = `${start.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
		})} ${start.getFullYear()}`;
		const formattedEndDate = `${end.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
		})} ${end.getFullYear()}`;

		return `${formattedStartDate} - ${formattedEndDate}`;
	};
}
