export const checksavedUser = () => {
	const savedUser = JSON.parse(localStorage.getItem("admin_state_laws"));
	const userSession = JSON.parse(sessionStorage.getItem("admin_state_laws"));

	if (userSession) {
		let timeDifference =
			(new Date() - new Date(userSession.logTime)) / 1000;

		if (timeDifference < userSession.expires_in) {
			return {
				validSession: true,
				stateId: userSession.state.state_id,
				token: userSession.access_token,
			};
		} else {
			return { validSession: false };
		}
	} else if (savedUser) {
		let timeDifference = (new Date() - new Date(savedUser.logTime)) / 1000;

		if (timeDifference < savedUser.expires_in) {
			return {
				validSession: true,
				stateId: savedUser.state.state_id,
				token: savedUser.access_token,
			};
		} else {
			localStorage.removeItem("admin_state_laws");
			return { validSession: false };
		}
	} else {
		return { session: false };
	}
};

export const checkAdmin = () => {
	const savedAdmin = JSON.parse(
		localStorage.getItem("super_admin_state_laws")
	);
	const adminSession = JSON.parse(
		sessionStorage.getItem("super_admin_state_laws")
	);

	if (adminSession) {
		let timeDiff = (new Date() - new Date(adminSession.logTime)) / 1000;
		if (timeDiff < adminSession.expires_in) {
			return {
				session: true,
				token: adminSession.access_token,
			};
		} else {
			return { session: false };
		}
	} else if (savedAdmin) {
		let timeDifference = (new Date() - new Date(savedAdmin.logTime)) / 1000;

		if (timeDifference < savedAdmin.expires_in) {
			return {
				session: true,
				token: savedAdmin.access_token,
			};
		} else {
			return { session: false };
		}
	} else {
		return { session: false };
	}
};
export const deleteAdminSession = () => {
	sessionStorage.removeItem("super_admin_state_laws");
	localStorage.removeItem("super_admin_state_laws");
};

export const deleteSession = () => {
	sessionStorage.removeItem("admin_state_laws");
	localStorage.removeItem("admin_state_laws");
};
