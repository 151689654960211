import axios from "axios";
import React, { useRef, useEffect } from "react";
import { adminAPI } from "../../utils/apiStrings";

const OverviewOptions = (props) => {
	const { userId, id, setId, closeOptions, setResponse } = props;
	const optionsRef = useRef(null);

	const detectClick = (e) => {
		if (optionsRef.current && !optionsRef.current.contains(e.target)) {
			closeOptions();
		}
	};

	const accountAction = async (id, action) => {
		try {
			const response = await axios.get(`${adminAPI()}/${id}/${action}`);
			console.log(response);
			setResponse(() => {
				return {
					status: response.status,
					message: response.data.message,
				};
			});
		} catch (error) {
			console.log(error.response.status);
			setResponse(() => {
				return {
					status: error.response.status,
					message: error.response?.data.message,
				};
			});
		}
	};

	useEffect(() => {
		document.addEventListener("click", detectClick, true);
		return () => {
			document.removeEventListener("click", detectClick, true);
		};
	}, [closeOptions]);

	return (
		<div
			ref={optionsRef}
			className={`options ${userId === id ? "options__visible" : ""}`}>
			<div
				className={`option`}
				onClick={() => accountAction(userId, "activate")}>
				Activate
			</div>
			<div
				onClick={() => accountAction(userId, "disable")}
				className={`option`}>
				Disable
			</div>
			<div
				className={`option`}
				onClick={() => accountAction(userId, "delete")}>
				Delete
			</div>
		</div>
	);
};

export default OverviewOptions;
