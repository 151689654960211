import React from "react";
import { useLocation } from "react-router-dom";

const Hero = ({ stateName }) => {
	const {} = useLocation();

	return (
		<div className="hero">
			<div className="hero__text">
				{stateName && (
					<h2 className="hero__text--heading">
						{`Overview of Purchase of Laws of ${stateName} State in `}
						<span className="hero__text--lp">Lawpavilion</span>
					</h2>
				)}

				<div className="features">
					<div className="features__heading">Features include:</div>
					<div className="features__listing">
						<div className="feature">
							<img
								src="/images/check.png"
								alt=""
							/>
							<span>Sales from Lifetime License</span>
						</div>
						<div className="feature">
							<img
								src="/images/check.png"
								alt=""
							/>
							<span>Bundle Sales</span>
						</div>
						<div className="feature">
							<img
								src="/images/check.png"
								alt=""
							/>
							<span> Sales from Subscriptions</span>
						</div>
						<div className="feature">
							<img
								src="/images/check.png"
								alt=""
							/>
							<span>Unit Sales</span>
						</div>
					</div>
				</div>
				<div className="hero__logo">
					<img
						src={`/images/${stateName.toLowerCase()}-coa.png`}
						alt=""
					/>
				</div>
			</div>
			<div className="hero__images">
				<img
					src="/images/blob.svg"
					alt=""
					className="hero__image hero__image--blob"
				/>
				<img
					src="/images/artwork.png"
					alt=""
					className="hero__image hero__image--art"
				/>
			</div>
		</div>
	);
};

export default Hero;
