import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminOverview from "../pages/Admin/AdminOverview";
import AdminTransaction from "../pages/Admin/AdminTransaction";
import AdminDashboard from "../pages/AdminDashboard";
import AdminLogin from "../pages/AdminLogin";
import { checkAdmin } from "../utils/session";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Admin = () => {
	const navigate = useNavigate();
	const [session, setSession] = useState(false);
	const [token, setToken] = useState("");

	const getToken = async () => {
		const { session, token } = checkAdmin();
		setSession(session);
		setToken(token);

		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		if (!session) {
			navigate("login");
		}
	};

	useEffect(() => {
		getToken();
	}, [session, token]);

	return (
		<div>
			<Routes>
				<Route
					path="/*"
					element={<AdminDashboard />}>
					<Route
						index
						element={<AdminOverview />}
					/>
					<Route
						path="transactions"
						element={<AdminTransaction />}
					/>
				</Route>
				<Route
					path="login"
					element={<AdminLogin />}
				/>
			</Routes>
		</div>
	);
};

export default Admin;
