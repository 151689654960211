import React from "react";

const AuthHero = ({ heading, subheading, image }) => {
	return (
		<div className="auth__hero">
			<div className="auth__hero--headings">
				<h3>{heading}</h3>
				<h4>{subheading}</h4>
			</div>
			<div className="auth__hero--image">
				<img
					src={image}
					alt="hero"
				/>
			</div>
		</div>
	);
};

export default AuthHero;
