import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const LawCard = ({ law, url }) => {
    return (
        <Link
            to={url}
            key={law.id}
        >
            <div className="laws__card">
                <div className="cover">
                    <img
                        src="/images/law.png"
                        alt=""
                    />
                </div>
                <div className="description">
                    <h4 className="title">{law.name} </h4>
                    {/* <div className="sales">
            <p className="amount">600 Sales</p>
            <div className="rating">
              <span className="rating__stars"></span>
              <span className="rating__total">(25 ratings)</span>
            </div>
          </div> */}
                </div>
            </div>
        </Link>
    );
};
