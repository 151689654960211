import React from "react";
import NewUserForm from "./NewUserForm";

const OverLay = ({ overlay, setOverlay, content }) => {
	const switchContent = () => {
		switch (content) {
			case "new_user":
				return <NewUserForm />;

			default:
				break;
		}
	};
	return (
		<div className={`overlay ${overlay ? "overlay__show" : ""}`}>
			<span
				className="overlay__close"
				onClick={() => setOverlay(!overlay)}></span>
			<div className="overlay__content">{switchContent(content)}</div>
		</div>
	);
};

export default OverLay;
