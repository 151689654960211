import React from "react";
import { useField } from "formik";

const Button = ({ type, text, loading }) => {
	return (
		<button
			type={type}
			className="auth__form-button">
			{loading ? <span className="auth__spinner"></span> : text}
		</button>
	);
};

export default Button;
