import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import FormHeadings from "../components/FormComponents/FormHeadings";
import * as Yup from "yup";
import Input from "../components/FormComponents/Input";
import Password from "../components/FormComponents/Password";
import Button from "../components/FormComponents/Button";
import axios from "axios";
import { adminAPI } from "../utils/apiStrings";
import { useNavigate } from "react-router-dom";
import AuthHero from "../components/FormComponents/AuthHero";
import CheckBox from "../components/FormComponents/CheckBox";
import { Link } from "react-router-dom";

const AdminLogin = () => {
	const navigate = useNavigate();
	const apiURL = adminAPI();
	const [apiError, setApiError] = useState("");
	const [loading, setLoading] = useState(false);

	const signInAdmin = (values) => {
		setLoading(true);

		axios
			.post(`${apiURL}/login`, values)
			.then((res) => {
				const { data } = res.data;
				if (values.remember) {
					localStorage.setItem(
						"super_admin_state_laws",
						JSON.stringify({ ...data, logTime: new Date() })
					);
				}
				sessionStorage.setItem(
					"super_admin_state_laws",
					JSON.stringify({ ...data, logTime: new Date() })
				);

				navigate("/admin/");
			})
			.catch((err) => {
				setLoading(false);
				if (!err.status) {
					setApiError("Something went wrong.Try again");
				} else {
					setApiError(err.response.data.message);
				}
			});
	};

	useEffect(() => {
		return () => {};
	}, [apiError]);

	return (
		<div className="auth">
			<div className="auth__form-wrapper">
				<div className="auth__cards">
					<div className="auth__card auth__card--form">
						<FormHeadings
							heading="Admin Sign In"
							subheading="Access Admin Dashboard"
						/>
						<Formik
							initialValues={{ email: "", password: "" }}
							validationSchema={Yup.object({
								email: Yup.string()
									.email("Invalid email address")
									.required("Email is required"),
								password:
									Yup.string().required("Enter Passsword"),
								remember: Yup.boolean(),
							})}
							onSubmit={signInAdmin}>
							<Form className="auth__form">
								<Input
									label="Email"
									className="auth__form-control"
									type="email"
									name="email"
									id="email"
								/>

								<Password
									label="Password"
									className="auth__form-control"
									name="password"
									id="password"
								/>
								<div className="auth__options">
									<CheckBox
										name="remember"
										id="remember"
										text="Remember Me"></CheckBox>
									<Link
										to="/forgot-password"
										className="auth__forgot">
										Forgot Password
									</Link>
								</div>

								<Button
									type="submit"
									text="Sign In"
									loading={loading}
								/>
								{apiError ? (
									<span className="auth__error">
										{apiError}
									</span>
								) : null}
							</Form>
						</Formik>
					</div>
				</div>
			</div>
			<AuthHero
				heading="Welcome to State Laws Dashboard"
				subheading="For simple, organized and cumulative access to the
						insights of the purchase of all states laws"
				image="/images/admin-dashboard.png"
			/>
		</div>
	);
};

export default AdminLogin;
