import { useField } from "formik";
import React from "react";

const Select = ({ label, className, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<div>
			<div className="auth__form-group">
				<label
					htmlFor={props.id || props.name}
					className="auth__form-label">
					{label}
				</label>
				<select
					{...props}
					{...field}
					className="auth__form-control">
					<option value="">{props.placeholder}</option>
					{props.options.map((option, index) => (
						<option
							value={option.id ? option.id : option}
							key={index}>
							{option.name ? option.name : option}
						</option>
					))}
				</select>
			</div>
			{meta.touched && meta.error ? (
				<span className="auth__error">{meta.error}</span>
			) : null}
		</div>
	);
};

export default Select;
