import TopBar from "./components/TopBar";
import "./sass/main.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import StateLaws from "./pages/StateLaws";
import States from "./pages/States";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Admin from "./modules/Admin";

function App() {
	return (
		<div className="">
			<Router>
				<Routes>
					<Route
						path="/"
						exact
						element={<Login />}
					/>
					<Route
						path="/forgot-password"
						exact
						element={<ForgotPassword />}
					/>
					<Route
						path="/reset-password"
						exact
						element={<ResetPassword />}
					/>
					<Route
						path="/states/:id/*"
						exact
						element={<States />}
					/>
					<Route
						path="/admin/*"
						element={<Admin />}
					/>
				</Routes>
			</Router>
		</div>
	);
}

export default App;
