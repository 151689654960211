import React from "react";
import LawDetails from "../components/LawDetails";
import StateLaws from "./StateLaws";
import TopBar from "../components/TopBar";
import { Routes, Route } from "react-router-dom";

const States = () => {
	return (
		<div className="laws-wrapper">
			<TopBar />
			<Routes>
				<Route
					path="/"
					exact
					element={<StateLaws />}
				/>

				<Route
					path="/laws/:id"
					exact
					element={<LawDetails />}
				/>
			</Routes>
		</div>
	);
};

export default States;
