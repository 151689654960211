import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import OverLay from "../../components/Admin/OverLay";
import UserTable from "../../components/Admin/UserTable";
import OverviewCard from "../../components/OverviewCard";
import { adminAPI, baseAPI, statAPI } from "../../utils/apiStrings";
import { checkAdmin } from "../../utils/session";
import { useNavigate } from "react-router-dom";

const AdminOverview = () => {
	const navigate = useNavigate();

	const [users, setUsers] = useState([]);
	const [uniqueStates, setUniqueStates] = useState([]);
	const [id, setId] = useState("");
	const [overlay, setOverlay] = useState(false);
	const [overlayContent, setOverlayContent] = useState("");
	const [apiError, setApiError] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [filter, setFilter] = useState("");
	const [searchResult, setSearchResult] = useState([]);
	const [filterResult, setFilterResult] = useState([]);
	const [statesObj, setStatesObj] = useState([]);
	const [response, setResponse] = useState({ status: "", message: "" });
	const [outrightSales, setOutrightSales] = useState("");
	const [individualSales, setIndividualSales] = useState("");

	const statesRef = useRef(null);
	const usersRef = useRef(users);

	let usersArray = [];

	const getIndividualSales = async () => {
		const response = await axios.get(
			`${statAPI()}/all-sales?period=overall_sales`
		);

		const {
			total_outright_sales: outright,
			total_non_outright_sales: nonOutright,
		} = response.data.data;

		setOutrightSales(parseInt(outright));

		setIndividualSales(parseInt(nonOutright));
	};

	const getStates = async () => {
		let stateArray = [];
		try {
			const response = await axios.get(`${baseAPI()}/states`);
			const {
				data: { data: states },
			} = response;

			states.forEach((state) => {
				stateArray.push({ id: state.id, state: state.name });
			});

			statesRef.current = stateArray;
			setStatesObj(stateArray);
		} catch (error) {
			setApiError(error.response.data.message);
		} finally {
			getUsers();
		}
	};

	const getUsers = async () => {
		try {
			const response = await axios.get(`${adminAPI()}/all-users`);
			const {
				data: {
					data: { data },
				},
			} = response;
			data.forEach(async (user) => {
				let stateName = await getStateName(user.state_id);
				user.state = stateName;
			});

			usersRef.current = data;
			setUsers(data);
		} catch (error) {}
	};

	const activateBackDrop = (modal) => {
		setOverlay(true);
		setOverlayContent(modal);
	};

	const createUser = () => {
		setOverlay(true);
		setOverlayContent("new_user");
	};
	const sortBy = (filter) => {
		setFilter(filter);
		let result;

		// Check if a search has been carried out

		let currentData = searchResult.length ? searchResult : users;

		switch (filter) {
			case "Name":
				result = currentData.sort((a, b) => {
					let userA = a.username.toLowerCase();
					let userB = b.username.toLowerCase();

					return userA < userB ? -1 : userA > userB ? 1 : 0;
				});
			case "State":
				result = currentData.sort((a, b) => {
					let userA = a.state.toLowerCase();
					let userB = b.state.toLowerCase();

					return userA < userB ? -1 : userA > userB ? 1 : 0;
				});
		}
		setFilterResult(result);
	};

	const searchUser = (string) => {
		setSearchTerm(string.toLowerCase());

		setFilter("");

		// let modifiedUsers = users.map((user) => {
		// 	let fullName = user.first_name + " " + user.last_name;
		// 	return { ...user, fullName: fullName.toLowerCase() };
		// });
		let match = users.filter((user) =>
			user.username.toLowerCase().includes(string.toLowerCase())
		);
		setSearchResult(match);
	};

	const closeOptions = () => {
		setId(null);
	};

	const getStateName = async (id) => {
		let state = statesRef.current.find((state) => state.id === id);
		return state.state;
	};

	useEffect(() => {
		// getIndividualSales();
		const { session, token } = checkAdmin();
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		if (!session) {
			navigate("login");
		}

		getStates();
	}, [response]);

	return (
		<div className="overview">
			<div
				className={`overview__response ${
					response.status
						? response.status?.toString().includes("20")
							? "overview__response--success"
							: "overview__response--error"
						: ""
				}`}
				onClick={() => setResponse({ status: "" })}>
				{response.message || "Action Successful"} &#x24E7;
			</div>
			<div className="overview__cards">
				<OverviewCard
					image="/images/total-users.svg"
					color="purple"
					label="Total Number of Users"
					text={users?.length}
				/>
				<OverviewCard
					image="/images/scales.svg"
					label="Total Number of States"
					text={statesObj?.length}
					color="blue"
				/>
				{/* <OverviewCard
					image="/images/cost-total.svg"
					label="Total Amount Made"
					color="red"
					text={(outrightSales + individualSales).toLocaleString(
						"en-NG",
						{
							style: "currency",
							currency: "NGN",
						}
					)}
				/> */}
			</div>
			<div className="overview__users">
				<div className="overview__actions">
					<div className="overview__view">
						<div className="overview__search">
							<span className="overview__icon overview__icon--search"></span>
							<input
								type="text"
								name="search"
								id="search"
								placeholder="Search"
								className="overview__input"
								onChange={(e) => searchUser(e.target.value)}
							/>
						</div>
						<div className="overview__filter">
							<img
								src="/images/filter.svg"
								alt="filter"
							/>
							<select
								className="overview__select"
								name="sort"
								id="sort"
								value={filter}
								onChange={(e) => sortBy(e.target.value)}>
								<option
									value=""
									disabled="disabled">
									Filter
								</option>
								<option value="Name">Name</option>
								<option value="State">State</option>
							</select>
						</div>
					</div>
					<div
						className="overview__create"
						onClick={() => activateBackDrop("new_user")}>
						<span className="overview__icon overview__icon--plus"></span>
						<span>Create New User</span>
					</div>
				</div>
				<div className="overview__table">
					<UserTable
						data={
							filter != ""
								? filterResult
								: searchResult.length
								? searchResult
								: users
						}
						setResponse={setResponse}
						id={id}
						setId={setId}
						closeOptions={closeOptions}
					/>
				</div>
			</div>
			<OverLay
				overlay={overlay}
				setOverlay={setOverlay}
				content={overlayContent}
			/>
		</div>
	);
};

export default AdminOverview;
